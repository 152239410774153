<template>
  <VendorsForm
    :vendor="vendor"
    :type="'create'"
    @save="createVendor"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    VendorsForm: () => import('./VendorsForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    vendor: {
      status: true,
      company_name: null,
      trading_name: null,
      email: null,
      cnpj: null,
      site: null,
      segment_id: null,
      note: null,
      contacts: [{ phones: [{ number: null }], email: '' }],
      locations: [
        {
          cep: '',
          address: '',
          number: '',
          district: '',
          uf: '',
          city: '',
        },
      ],
      payments: [],
      transfers: [{}],
    },
  }),
  methods: {
    createVendor() {
      if (this.isFormValid) {
        this.askToLeave = false
        this.$api
          .post('vendors/store', this.vendor)
          .then(() => {
            console.log(this.vendor)
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Fornecedor cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'vendors' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar esta fornecedor',
              caption:
                'Verifique se os campos estão preenchidos fornecedorretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar esta fornecedor',
          caption:
            'Verifique se os campos estão preenchidos fornecedorretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
